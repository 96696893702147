import Vue from 'vue'
import {
  Row,
  Col,
  Button,
  Form,
  FormItem,
  Input,
  CheckboxGroup,
  Checkbox,
  Select,
  Option,
  Drawer,
  Message,
} from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import '../assets/sass/element-variables.scss'

const components = [
  Row,
  Col,
  Button,
  Form,
  FormItem,
  Input,
  CheckboxGroup,
  Checkbox,
  Select,
  Option,
  Drawer,
]

const Element = {
  install(Vue) {
    components.forEach((component) => {
      Vue.component(component.name, component)
    })
  },
}

// Global context
Vue.prototype.$message = Message

Vue.prototype.$toast = (opts) => {
  Message({
    ...opts,
    type: 'info',
    customClass: 'toast',
  })
}

Vue.use(Element, { locale })
