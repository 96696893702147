const cookieTipHTML = `
<div class="cookie-tip cookie-tips">
  <p>当社では、(i)ウェブサイトでの体験を向上させるためにパーソナライズされたコンテンツや広告を提供すること、及び(ii)当社ウェブサイトを改善させるためにウェブサイトのトラフィックを分析することを目的に、サードパーティーから提供を受ける Cookie を含めた Cookie を使用及び第三者へ提供しています。これにより当社は詳細情報の保存、関心のあるサービスの表示及び継続が可能です。当社の Cookie の利用目的、Cookie 設定の手続の詳細については、当社の「<a rel="noopener noreferrer" target="_blank" href="//poper.co/cookie-policy">Cookie ポリシー</a>」をご覧ください。個人情報の取扱いについては、「<a rel="noopener noreferrer" target="_blank" href="//poper.co/privacy-policy">PRIVACY POLICY</a>」をご覧ください。</p>
  <button class="accept btn-accept">OK</button>

  <style scoped>
.cookie-tip {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
  background-color: rgba(0,0,0,.7);

  & p {
    max-width: 800px;
    font-size: 12px;
    line-height: 1.5;
    text-align: justify;
    color: #fff;

    > a {
      color: #fff;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #fff;
        opacity: 0.6;
      }
    }
  }

  .accept {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 40px;

    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;

    background-color: #fff;
    border-radius: 4px;
    border: 0;
    color: #000;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 800px) {
    z-index: 100;

    p {
      margin-right: 12px;
      font-size: 10px;
      line-height: 14px;

      > a {
        font-size: 10px;
      }
    }

    .accept {
      height: 32px;
      font-size: 12px;
    }
  }
}
  </style>
</div>

`

export function addCookieTip() {
  const haveAgreed =
    localStorage.getItem('agreed_with_cookie_policy') === 'true'

  const haveInserted = Boolean(document.querySelector('.cookie-tip'))

  if (haveAgreed || haveInserted) {
    return
  }

  const $body = document.body

  $body.insertAdjacentHTML('beforeend', cookieTipHTML)

  const $cookieTip = document.querySelector('.cookie-tip')
  const $accept = $cookieTip?.querySelector('.cookie-tip .accept')

  $accept?.addEventListener('click', () => {
    localStorage.setItem('agreed_with_cookie_policy', 'true')
    $cookieTip?.remove()
  })
}
