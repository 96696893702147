import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _71e309c3 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _7b14357a = () => interopDefault(import('../pages/adviser/index.vue' /* webpackChunkName: "pages/adviser/index" */))
const _f0770d54 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _b2ae241e = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _d06dcafc = () => interopDefault(import('../pages/emailchange.vue' /* webpackChunkName: "pages/emailchange" */))
const _553dfa84 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _b0a0ffba = () => interopDefault(import('../pages/example/index.vue' /* webpackChunkName: "pages/example/index" */))
const _31b2583a = () => interopDefault(import('../pages/letter/index.vue' /* webpackChunkName: "pages/letter/index" */))
const _5b42879f = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3fc17a65 = () => interopDefault(import('../pages/logoff.vue' /* webpackChunkName: "pages/logoff" */))
const _c1474332 = () => interopDefault(import('../pages/logoffdone.vue' /* webpackChunkName: "pages/logoffdone" */))
const _5b730a82 = () => interopDefault(import('../pages/passwordforget.vue' /* webpackChunkName: "pages/passwordforget" */))
const _40814f6c = () => interopDefault(import('../pages/passwordreset.vue' /* webpackChunkName: "pages/passwordreset" */))
const _421ef684 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7d150e46 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _117c60df = () => interopDefault(import('../pages/registerdone.vue' /* webpackChunkName: "pages/registerdone" */))
const _3bf2edb6 = () => interopDefault(import('../pages/rule.vue' /* webpackChunkName: "pages/rule" */))
const _5fececea = () => interopDefault(import('../pages/seminar/index.vue' /* webpackChunkName: "pages/seminar/index" */))
const _72a44431 = () => interopDefault(import('../pages/serial/index.vue' /* webpackChunkName: "pages/serial/index" */))
const _3c14975a = () => interopDefault(import('../pages/tags.vue' /* webpackChunkName: "pages/tags" */))
const _02e179f6 = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _4cec0bb3 = () => interopDefault(import('../pages/topic/tags.vue' /* webpackChunkName: "pages/topic/tags" */))
const _35b25c6b = () => interopDefault(import('../pages/adviser/_id.vue' /* webpackChunkName: "pages/adviser/_id" */))
const _e9eac252 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _38552f4b = () => interopDefault(import('../pages/example/_id.vue' /* webpackChunkName: "pages/example/_id" */))
const _7de319ea = () => interopDefault(import('../pages/letter/_id.vue' /* webpackChunkName: "pages/letter/_id" */))
const _4cc521d2 = () => interopDefault(import('../pages/seminar/_id.vue' /* webpackChunkName: "pages/seminar/_id" */))
const _2e279ed9 = () => interopDefault(import('../pages/serial/_id.vue' /* webpackChunkName: "pages/serial/_id" */))
const _28ab77c0 = () => interopDefault(import('../pages/topic/_name.vue' /* webpackChunkName: "pages/topic/_name" */))
const _dcca36f0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _71e309c3,
    name: "about"
  }, {
    path: "/adviser",
    component: _7b14357a,
    name: "adviser"
  }, {
    path: "/contact",
    component: _f0770d54,
    name: "contact"
  }, {
    path: "/cookie-policy",
    component: _b2ae241e,
    name: "cookie-policy"
  }, {
    path: "/emailchange",
    component: _d06dcafc,
    name: "emailchange"
  }, {
    path: "/error",
    component: _553dfa84,
    name: "error"
  }, {
    path: "/example",
    component: _b0a0ffba,
    name: "example"
  }, {
    path: "/letter",
    component: _31b2583a,
    name: "letter"
  }, {
    path: "/login",
    component: _5b42879f,
    name: "login"
  }, {
    path: "/logoff",
    component: _3fc17a65,
    name: "logoff"
  }, {
    path: "/logoffdone",
    component: _c1474332,
    name: "logoffdone"
  }, {
    path: "/passwordforget",
    component: _5b730a82,
    name: "passwordforget"
  }, {
    path: "/passwordreset",
    component: _40814f6c,
    name: "passwordreset"
  }, {
    path: "/privacy",
    component: _421ef684,
    name: "privacy"
  }, {
    path: "/register",
    component: _7d150e46,
    name: "register"
  }, {
    path: "/registerdone",
    component: _117c60df,
    name: "registerdone"
  }, {
    path: "/rule",
    component: _3bf2edb6,
    name: "rule"
  }, {
    path: "/seminar",
    component: _5fececea,
    name: "seminar"
  }, {
    path: "/serial",
    component: _72a44431,
    name: "serial"
  }, {
    path: "/tags",
    component: _3c14975a,
    name: "tags"
  }, {
    path: "/user",
    component: _02e179f6,
    name: "user"
  }, {
    path: "/topic/tags",
    component: _4cec0bb3,
    name: "topic-tags"
  }, {
    path: "/adviser/:id",
    component: _35b25c6b,
    name: "adviser-id"
  }, {
    path: "/article/:id?",
    component: _e9eac252,
    name: "article-id"
  }, {
    path: "/example/:id",
    component: _38552f4b,
    name: "example-id"
  }, {
    path: "/letter/:id",
    component: _7de319ea,
    name: "letter-id"
  }, {
    path: "/seminar/:id",
    component: _4cc521d2,
    name: "seminar-id"
  }, {
    path: "/serial/:id",
    component: _2e279ed9,
    name: "serial-id"
  }, {
    path: "/topic/:name?",
    component: _28ab77c0,
    name: "topic-name"
  }, {
    path: "/",
    component: _dcca36f0,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
