/**
 * common short and specific functions
 */

import { ellipsis } from '@/plugins/filters'

export const getHead = ({
  title,
  description,
  image,
  setOgp = true,
  ogpTitle,
  ogpDescription,
  hasNoIndexTag,
}) => {
  const head = {
    meta: [],
  }

  description = ellipsis(description, 150)

  if (hasNoIndexTag) {
    head.meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
  }

  if (title) {
    head.title = title
  }

  if (description) {
    head.meta.push({
      hid: 'description',
      name: 'description',
      content: description,
    })
  }

  if (setOgp) {
    ogpTitle = ogpTitle || title

    if (ogpTitle) {
      head.meta.push({
        hid: 'og:title',
        name: 'og:title',
        content: ogpTitle,
      })
    }

    ogpDescription = ogpDescription || description

    if (ogpDescription) {
      head.meta.push({
        hid: 'og:description',
        property: 'og:description',
        content: ogpDescription,
      })
    }

    if (image) {
      head.meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: image,
      })
    }
  }

  return head
}
