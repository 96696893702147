import Vue from 'vue'
import { format } from 'date-fns'

export const formatDate = (date, formatText, options) => {
  if (typeof date === 'string') {
    // fixed ios time
    date = new Date(date.replace(' ', 'T'))
  }

  try {
    return format(date, formatText, options)
  } catch (e) {
    return ''
  }
}

export const ellipsis = (text, max = 250, symbol = '...') => {
  if (!text) {
    return text
  }

  if (text.length <= max) {
    return text
  }

  return `${text.slice(0, max)}${symbol}`
}

const filters = {
  formatDate,
  ellipsis,
}

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

export default filters
