export const state = () => ({
  siteURL: '',
})

export const mutations = {
  init(state, newState) {
    Object.assign(state, newState)
  },
}

export const actions = {
  async nuxtServerInit({ commit }, { req }) {
    // init
    commit('init', {
      siteURL: `${process.env.isHttps ? 'https' : 'http'}://${
        req.headers.host
      }`,
    })

    // sidebar
    const sidebarRes = await this.$axios.$get('/sidebar')

    const {
      ad: ads,
      seminar,
      commilita: letters,
      interview: guests,
      cases: examples,
      new_article: news,
      ranking_article: ranks,
    } = sidebarRes.data

    const sidebar = {}

    if (ads) {
      sidebar.ads = ads.map((item) => {
        return {
          img: item.attachment?.file_url,
          link: item.href,
        }
      })
    }

    if (seminar?.length > 0) {
      sidebar.seminar = seminar.map((item) => {
        return {
          img: item.attachment?.file_url,
          link: `/seminar/${item.id}`,
        }
      })
    }

    if (letters?.length > 0) {
      sidebar.letters = letters.map((item) => {
        return {
          img: item.attachment?.file_url,
          link: `/letter/${item.id}`,
        }
      })
    }

    if (guests?.length > 0) {
      sidebar.guests = guests.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.name,
          subTitle: item.description,
          link: `/adviser/${item.id}`,
        }
      })
    }

    if (examples?.length > 0) {
      sidebar.examples = examples.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.date,
          link: `/example/${item.id}`,
        }
      })
    }

    if (news?.length > 0) {
      sidebar.news = news.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.date,
          link: `/article/${item.id}`,
        }
      })
    }

    if (ranks?.length > 0) {
      sidebar.ranks = ranks.map((item) => {
        return {
          thumb: item.attachment?.file_url,
          title: item.title,
          date: item.date,
          link: `/article/${item.id}`,
        }
      })
    }

    commit('sidebar/init', sidebar)
  },
}
