import { Message } from 'element-ui'
import { intercept } from '~/utils'

export default function ({ $axios, redirect }) {
  $axios.interceptors.request.use((request) => {
    if (
      request.method === 'get' &&
      request.params &&
      intercept.handleRequestParam(request.params)
    ) {
      redirect({
        name: 'error',
        query: {
          status: '404',
        },
      })
      throw new Error('404 Error')
    }
    return request
  })

  $axios.onRequest((config) => {
    // Mock set
    if (process.env.mockBaseURL) {
      if (
        !process.env.mockList ||
        process.env.mockList.some((path) => config.url.includes(path))
      ) {
        config.baseURL = process.env.mockBaseURL
      }
    }

    return config
  })

  $axios.onError((error) => {
    const { response } = error

    // handle server error
    if (process.server) {
      const statusCode = response?.status || error.statusCode

      if (statusCode >= 500 && statusCode < 600) {
        process.sentry.captureException(error)
      }

      redirect({
        name: 'error',
        query: {
          status: response?.status || error.statusCode,
        },
      })

      return
    }

    if (!response) {
      throw new Error(error)
    }

    if ([403, 422, 500].includes(response.status)) {
      const errMsg = response.data?.message || 'エラーが発生しました。'

      Message({
        message: errMsg,
        type: 'info',
        customClass: 'toast',
      })

      return response
    }

    if ([404].includes(response.status)) {
      throw new Error('API 404 Error')
    }

    throw new Error('API Error')
  })
}
