const blackList = ['owned-media.herokuapp.com']
const uaBlackRegList = [/msie\s6/i]

export default function (req, res, next) {
  // disable some ua request
  if (uaBlackRegList.some((ua) => ua.test(req.headers['user-agent']))) {
    res.writeHead(403).end()

    return
  }

  // disable some domain
  if (blackList.some((domain) => req.headers.host.includes(domain))) {
    res.writeHead(403).end()

    return
  }

  res?.setHeader('X-Frame-Options', 'SAMEORIGIN')

  next()
}
